<script setup>
import serversGermany from '@/../images/servers-germany.png';
import { Link, usePage } from '@inertiajs/vue3';
import { computed } from 'vue';
import NewsletterInput from '@/Components/NewsletterInput.vue';
import ApplicationLogo from '@/Components/ApplicationLogo.vue';

const page = usePage();
const user = computed(() => page.props.auth.user);

const currentYear = new Date().getFullYear();
</script>

<template>
    <footer class="footer max-w-7xl w-full p-8 rounded-t-2xl bg-primary text-primary-content">
        <aside>
            <div>
                <ApplicationLogo type="white"></ApplicationLogo>
            </div>
            <div class="font-bold mb-8 text-lg">Plan ahead</div>

            <div>
                <img :src="serversGermany" alt="Servers Germany" class="h-10 rounded-lg inline-block">
            </div>
        </aside>
        <nav>
            <span class="font-bold">
                {{ $t('footer.heading_general') }}
            </span>
            <div class="flex flex-col lg:flex-row gap-8">
                <div class="w-full lg:w-1/2">
                    <Link  v-if="!user" class="link link-hover block mb-2" :href="route('landing-page')">
                        {{ $t('footer.landing_page') }}
                    </Link>
                    <a class="link link-hover block mb-2" href="https://blog.afterlife.de" target="_blank">
                        {{ $t('footer.knowledge') }}
                    </a>
                    <a class="link link-hover block mb-2" :href="route('help-center')" target="_blank">
                        {{ $t('footer.faq') }}
                    </a>
                    <Link class="link link-hover block mb-2" :href="route('contact.index')">
                        {{ $t('footer.contact') }}
                    </Link>
                </div>
                <div class="w-full lg:w-1/2">
                    <Link v-if="!user" class="link link-hover block mb-2" :href="route('landing-page') + '#functions'">
                        {{ $t('footer.functions') }}
                    </Link>
                    <Link v-if="!user" class="link link-hover block mb-2" :href="route('login')">
                        {{ $t('footer.login') }}
                    </Link>
                    <Link v-if="!user" class="link link-hover block mb-2" :href="route('register')">
                        {{ $t('footer.register') }}
                    </Link>
                </div>
            </div>
        </nav>
        <nav v-if="!user">
            <div>
                <span class="font-bold">
                    {{ $t('newsletter.heading') }}
                </span>
                <p class="mb-2">
                    {{ $t('newsletter.subheading') }}
                </p>
            </div>
            <NewsletterInput
                :subscribe-route="route('newsletter.subscribe')"
            ></NewsletterInput>
        </nav>
    </footer>
    <div class="max-w-7xl w-full flex flex-col lg:flex-row bg-primary text-primary-content p-8 justify-end text-sm gap-3">
        <Link class="link link-hover block" :href="route('legal.imprint')">
            {{ $t('footer.imprint') }}
        </Link>
        <Link class="link link-hover block" :href="route('legal.privacy')">
            {{ $t('footer.privacy') }}
        </Link>
        <Link class="link link-hover block" :href="route('legal.terms')">
            {{ $t('footer.terms') }}
        </Link>
        <a class="link link-hover block" href="#" onclick="CCM.openWidget(); return false;">
            {{ $t('footer.cookies') }}
        </a>
        <div>
            {{  $t('footer.copyright', {  year: currentYear }) }}
        </div>
    </div>
</template>
