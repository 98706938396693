<script setup>
import { useForm } from '@inertiajs/vue3';
import TextInput from '@/Components/TextInput.vue';
import InputError from '@/Components/InputError.vue';

const props = defineProps({
    subscribeRoute: null,
    nameNeeded: { type: Boolean, default: false },
    successAlert: { type: String, default: null },
})

const form  = useForm({
    email: '',
    name: '',
});

const subscribe = () => {
    form.post(props.subscribeRoute, {
        onSuccess: () => form.reset(),
    });
}
</script>

<template>
    <div>
        <div v-if="nameNeeded" class="flex gap-2 mb-3">
            <TextInput
                id="name"
                v-model="form.name"
                type="text"
                :placeholder="$t('placeholders.full_name')"
                class="w-full"
                required
            />
        </div>

        <div class="flex gap-3">
            <TextInput
                id="email"
                v-model="form.email"
                type="email"
                :placeholder="$t('placeholders.email')"
                class="w-full"
            />

            <div class="btn btn-warning" @click="subscribe()">
                <i class="far fa-chevron-right"></i>
            </div>
        </div>

        <InputError :message="form.errors.name" />

        <InputError :message="form.errors.email" />

    </div>
</template>
